import React, { useState, useEffect } from "react";
import { register } from "../../api/auth";
import PageWrapper from "../../components/PageWrapper";
import "./index.css";
import { validateQueryParams } from "../../lib/auth";
import ErrorMessage from "../../components/ErrorMessage";
import { MdError } from "react-icons/md";

const Registration = () => {
  const [queryParamsAreValid, setQueryParamsAreValid] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirm_password: "",
    phone_number: "",
    address_line_1: "",
    address_line_2: "",
    town: "", 
    county: "",
    postcode: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (formData.password !== formData.confirm_password) {
        throw new Error("Passwords do not match");
      }
      await register(formData);
      window.location.href = `/oauth/login?${new URLSearchParams(window.location.search).toString()}`;
    } catch (error) {
      console.error("Login failed", error);
    }
  };

  const handleGoBack = () => {
    window.history.back();
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setQueryParamsAreValid(validateQueryParams(params));
    setQueryParamsAreValid(true);
  }, [queryParamsAreValid]);

  return (
    <PageWrapper>
      <div className="login-container">
        <h2>Registration</h2>
        {queryParamsAreValid ? (
          <form onSubmit={handleSubmit} className="login-form">
            <div className="form-group">
              <label>Name</label>
              <input
                id="name"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                required
              />
            </div>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                required
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                id="password"
                value={formData.password}
                onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                required
              />
            </div>
            <div className="form-group">
              <label>Confirm Password</label>
              <input
                type="password"
                id="confirm_password"
                value={formData.confirm_password}
                onChange={(e) => setFormData({ ...formData, confirm_password: e.target.value })}
                required
              />
            </div>
            <div className="form-group">
              <label>Phone Number</label>
              <input
                type="text"
                id="phone_number"
                value={formData.phone_number}
                onChange={(e) => setFormData({ ...formData, phone_number: e.target.value })}
                required
              />
            </div>
            <div className="form-group">
              <label>Address Line 1</label>
              <input
                type="text"
                id="address_line_1"
                value={formData.address_line_1}
                onChange={(e) => setFormData({ ...formData, address_line_1: e.target.value })}
                required
              />
            </div>
            <div className="form-group">
              <label>Address Line 2</label>
              <input
                type="text"
                id="address_line_2"
                value={formData.address_line_2}
                onChange={(e) => setFormData({ ...formData, address_line_2: e.target.value })}
              />
            </div>
            <div className="form-group">
              <label>Town</label>
              <input
                type="text"
                id="town"
                value={formData.town}
                onChange={(e) => setFormData({ ...formData, town: e.target.value })}
                required
              />
            </div>
            <div className="form-group">
              <label>County</label>
              <input
                type="text"
                id="county"
                value={formData.county}
                onChange={(e) => setFormData({ ...formData, county: e.target.value })}
                required
              />
            </div>
            <div className="form-group">
              <label>Postcode</label>
              <input
                type="text"
                id="postcode"
                value={formData.postcode}
                onChange={(e) => setFormData({ ...formData, postcode: e.target.value })}
                required
              />
            </div>
            <button type="submit" className="login-button">
              Register
            </button>
          </form>
        ) : (
          <div>
            <ErrorMessage>
              <MdError className="error-icon" />
              <p>Bad registration state</p>
            </ErrorMessage>
            <button onClick={handleGoBack}>Go Back</button>
          </div>
        )}
      </div>
    </PageWrapper>
  );
};

export default Registration;
