import React from "react";
import PropTypes from "prop-types";
import "./index.css";

const PageWrapper = ({ children }) => {
  return (
    <div className="page-wrapper">
      <header className="page-header">
        <div className="header-content">
          <h1 className="page-title">Switch Waste</h1>
        </div>
      </header>
      <main className="page-content">{children}</main>
      <footer className="page-footer">
        <p>&copy; 2024 Switch Waste. All rights reserved.</p>
      </footer>
    </div>
  );
};

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageWrapper;
