import ENV from "./config";
import { fetchTokens } from "../api/auth";

export const validateQueryParams = (queryParams) => {
  const requiredParams = [
    "client_id",
    "redirect_uri",
    "response_type",
    "scope",
    "state",
    "code_challenge",
    "code_challenge_method",
  ];
  const missingParams = requiredParams.filter(
    (param) => !queryParams.has(param),
  );

  return missingParams.length === 0;
};

export const getAccessToken = () => localStorage.getItem("access_token");

export const isLoggedIn = () => {
  const accessToken = getAccessToken();
  if (accessToken === null) {
    return false;
  }

  // decode access token
  let decodedToken;
  try {
    decodedToken = JSON.parse(atob(accessToken.split(".")[1]));
  } catch (error) {
    console.error("Failed to decode access token", error);
    return false;
  }

  // check if the token is expired
  const expirationTime = decodedToken.exp * 1000;
  const currentTime = Date.now();
  if (expirationTime <= currentTime) {
    return false;
  }

  return true;
};

export const getRedirectUri = () =>
  `${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + window.location.port : ""}/user/oauth2-callback`;

export const generateOAuthParams = async () => {
  // generate a code challenge
  const code_verifier = crypto.getRandomValues(new Uint8Array(32));

  // hash the verifier with sha256
  const encoder = new TextEncoder();
  const data = encoder.encode(code_verifier);
  // store the code verifier in local storage
  localStorage.setItem("pkce_code_verifier", code_verifier);

  const hash = await crypto.subtle.digest("SHA-256", data);

  // convert the hash to base64
  const hashArray = Array.from(new Uint8Array(hash));
  const hashStr = hashArray.map((b) => String.fromCharCode(b)).join("");
  const code_challenge = btoa(hashStr)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");

  // state
  const state = crypto.getRandomValues(new Uint8Array(16)).join("");
  localStorage.setItem("oauth_state", state);

  // create the OAuth2 params
  const params = {
    client_id: ENV.get().REACT_APP_OAUTH2_CLIENT_ID,
    redirect_uri: getRedirectUri(),
    response_type: "code",
    scope: "all",
    state: state,
    code_challenge,
    code_challenge_method: "S256",
  };

  return params;
};

export const handleAuthorizationCode = async (code) => {
  // redirectUri when fetching tokens *must* match that stored in the clients DB, so this can be hard-coded really
  const redirectUri = "http://localhost:3001/oauth2";
  const tokens = await fetchTokens(
    code,
    ENV.get().REACT_APP_OAUTH2_CLIENT_ID,
    localStorage.getItem("pkce_code_verifier"),
    redirectUri,
  );
  if (!tokens.ok) {
    console.error("Failed to fetch tokens");
    return;
  }
  const data = await tokens.json();
  localStorage.setItem("access_token", data.access_token);
  localStorage.setItem("refresh_token", data.refresh_token);
  window.location.href = "/";
};

export const loginRedirect = async () => {
  const params = await generateOAuthParams();
  window.location.href = `/oauth/login?${new URLSearchParams(params)}`;
};
