import React, { useState, useEffect } from "react";
import PageWrapper from "../../components/PageWrapper";
import "./OAuthAuthorize.css";
import { useLocation } from "react-router-dom";
import { getApiUrl } from "../../api/main";

// This page is auth-service specific only!

const OAuthAuthorize = () => {
  const location = useLocation();
  const [clientId, setClientId] = useState("");
  const [redirectUri, setRedirectUri] = useState("");
  const [responseType, setResponseType] = useState("");
  const [scope, setScope] = useState("");
  const [state, setState] = useState("");
  const [codeChallenge, setCodeChallenge] = useState("");
  const [codeChallengeMethod, setCodeChallengeMethod] = useState("");
  const [preAuthToken, setPreAuthToken] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setClientId(params.get("client_id") || "");
    setRedirectUri(params.get("redirect_uri") || "");
    setResponseType(params.get("response_type") || "");
    setScope(params.get("scope") || "");
    setState(params.get("state") || "");
    setCodeChallenge(params.get("code_challenge") || "");
    setCodeChallengeMethod(params.get("code_challenge_method") || "");
    setPreAuthToken(params.get("pre_auth_token") || "");
  }, [location.search]);

  const handleAuthorize = async () => {
    const oauthParams = new URLSearchParams({
      client_id: clientId,
      redirect_uri: redirectUri,
      response_type: responseType,
      scope: scope,
      state: state,
      code_challenge: codeChallenge,
      code_challenge_method: codeChallengeMethod,
      pre_auth_token: preAuthToken,
    });

    try {
      const response = await fetch(
        getApiUrl(`/oauth/authorize?${oauthParams.toString()}`),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        // Handle success
        const json = await response.json();
        window.location.href = json.redirect;
      } else {
        console.error("Authorization failed", response);
        window.location.href = "/";
      }
    } catch (error) {
      console.error("Authorization failed <- on /oauth/authorize");
      console.error(error);
    }
  };

  const handleDeny = () => {
    alert("Authorization denied");
  };

  return (
    <PageWrapper>
      <div className="authorize-container">
        <h2>Authorize Application</h2>
        <div className="authorize-content">
          <p>
            Application <strong>{clientId}</strong> is requesting access to your
            account.
          </p>
          <div className="authorize-buttons">
            <button onClick={handleAuthorize} className="authorize-button">
              Authorize
            </button>
            <button onClick={handleDeny} className="deny-button">
              Deny
            </button>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default OAuthAuthorize;
