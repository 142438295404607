import React, { useState, useEffect } from "react";
import { login } from "../../api/auth";
import PageWrapper from "../../components/PageWrapper";
import "./Login.css";
import { validateQueryParams } from "../../lib/auth";
import ErrorMessage from "../../components/ErrorMessage";
import { MdError } from "react-icons/md";

// This page is auth-service specific only!

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [queryParamsAreValid, setQueryParamsAreValid] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(email, password);
    } catch (error) {
      console.error("Login failed", error);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setQueryParamsAreValid(validateQueryParams(params));
  }, [queryParamsAreValid]);

  return (
    <PageWrapper>
      <div className="login-container">
        <h2>Login</h2>
        {queryParamsAreValid ? (
          <form onSubmit={handleSubmit} className="login-form">
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="login-button">
              Login
            </button>
          </form>
        ) : (
          <ErrorMessage>
            <MdError className="error-icon" />
            <p>Bad login state</p>
          </ErrorMessage>
        )}
      </div>
    </PageWrapper>
  );
};

export default Login;
