import React from "react";
import PageWrapper from "../../components/PageWrapper";
import { useNavigate } from "react-router-dom";
import "./index.css";

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  return (
    <PageWrapper>
      <div className="not-found-container">
        <h2>404 - Page Not Found</h2>
        <p>Whoops! You appear to have navigated to a page which doesn't exist.</p>
        <button onClick={handleGoBack}>Go Back</button>
      </div>
    </PageWrapper>
  );
};

export default NotFound;
