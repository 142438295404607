import { getApiUrl } from "./main";

const getOauthParams = () => {
  // Get OAuth-related query parameters from the current page's URL
  const urlParams = new URLSearchParams(window.location.search);
  const oauthParams = [
    "client_id",
    "redirect_uri",
    "response_type",
    "scope",
    "state",
    "code_challenge",
    "code_challenge_method",
  ];

  // Collect only the OAuth-related parameters
  const params = new URLSearchParams();
  oauthParams.forEach((param) => {
    if (urlParams.has(param)) {
      params.set(param, urlParams.get(param));
    }
  });

  return params;
};

export const login = async (
  email,
  password,
  oauthParams = getOauthParams()
) => {
  try {
    const response = await fetch(
      getApiUrl(`/login?${oauthParams.toString()}`),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      }
    );

    if (response.ok) {
      console.log("Login successful");
      window.location.href = response.url;
    }
  } catch {
    console.error("Login failed");
  }
};

export const register = async (formData) => {
  const response = await fetch(getApiUrl("/register"), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  });

  if (!response.ok) {
    throw new Error("Registration failed");
  }
};

export const fetchTokens = async (
  code,
  clientId,
  codeVerifier,
  redirectUri
) => {
  const params = new URLSearchParams();
  params.append("grant_type", "authorization_code");
  params.append("code", code);
  params.append("client_id", clientId);
  params.append("code_verifier", codeVerifier);
  params.append("redirect_uri", redirectUri);

  return await fetch(getApiUrl("/oauth/token"), {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: params.toString(),
  });
};
