import React from "react";
import PropTypes from "prop-types";
import "./index.css";

const ErrorMessage = ({ children }) => {
  return (
    <div className="error-message">
      <div className="error-content">{children}</div>
    </div>
  );
};

ErrorMessage.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorMessage;
